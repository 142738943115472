
import AppMenu from '@/views/application-module/application-management/components/AppMenu.vue';
import PageInfo from '@/views/application-module/components/PageTemplate.vue';
import { defineComponent, reactive, ref, watch, onBeforeUnmount, toRefs, computed } from 'vue';
import { computeStatusLabel, statusColor } from '@/views/service-management/business-service/utils/service-status-map';
import { getAppDetail, currentApp } from '@/views/application-module/utils/dataCenter';
import AppEditDetail from '@/views/application-module/components/AppEditDetail.vue';
import { getAppsMain, initApplyApp, startApp, stopApp, syncAppStatusByTSF } from '@/api/app';
import { ElMessage } from 'element-plus';
import IframeDiv from '../components/IframeDiv.vue';
import { getShowBool } from '@/utils/permission-show-module';
import ReleaseDialog from '../components/ReleaseDialog.vue';
import ServiceName from '@/views/service-management/components/ServiceName.vue';
import { getServiceShowName, getTemplateTIcon } from '@/views/application-module/utils/utils';
import { templateEnum } from '@/views/application-module/utils/app-data-utils';
import DomainConfig from '@/views/service-management/business-service/components/domainConfig/DomainConfig.vue';
import { openDomainDialog } from '@/views/service-management/business-service/components/domainConfig/dataCenter';
import LogDialog from '@/views/service-management/business-service/components/LogDialog.vue';
import { useEnvAndServiceId } from '@/views/env/detail/detail';
import { removeEnvStorage } from '@/utils/env-storage';
import Version from '../components/upgrade-instance/Version.vue';
import { useRefrenceApp } from '../useRefrenceApp';

export default defineComponent({
  name: 'ApplicationDetail',
  components: {
    PageInfo,
    AppEditDetail,
    LogDialog,
    IframeDiv,
    ReleaseDialog,
    ServiceName,
    AppMenu,
    DomainConfig,
    Version,
  },
  setup() {
    const activeName = ref('first');
    // 获取路由信息
    const { serviceId: appId, env, envId, envName } = useEnvAndServiceId() as any;
    const appList = ref([]);
    const currentId = ref(appId);
    const releaseDialogRef = ref();
    // 组件初始化数据函数
    const init = () => {
      getAppDetail(String(appId));
      getAppsMain({
        page: 1,
        pageSize: 10000,
        keyword: '',
      }).then((res) => {
        appList.value = res.data.rows.filter((row: any) => row.delState === 0);
      });
      currentId.value = appId;
    };

    const appLogRef: any = ref(null);

    init();
    // 初始化应用状态数据
    const statusData = reactive({
      color: '',
      label: '',
      statusText: '',
    });
    statusData.label = computeStatusLabel(0)['30'];
    statusData.color = statusColor['30'];
    const appName = ref('');

    // 按钮不可点击控制
    const disableData = reactive({
      initDisabled: true,
      startDisabled: true,
      stopDisabled: true,
    });
    const initDisableArr = [10, 20, 30];
    const startDisableArr = [0, 10, 12, 20, 30];
    const stopDisableArr = [0, 10, 11, 12, 20, 22, 30, 31];
    const doingMap: any = {
      10: '应用初始化/变更中...',
      20: '应用启动中...',
      30: '应用停止中...',
    };

    let syncTimer: any = null;

    const syncStatusTimer = () => {
      syncTimer = setInterval(() => {
        syncAppStatusByTSF([Number(currentApp.value.id)]);
      }, 5000);
    };

    const clearSyncTimer = () => {
      clearInterval(syncTimer);
      syncTimer = null;
    };

    // 监听轮询请求，持续变更数据
    watch(currentApp, (nn) => {
      if (!nn) {
        return;
      }
      // eslint-disable-next-line
      // @ts-ignore
      statusData.label = computeStatusLabel(nn.initTimes || 0)[nn.status];
      // eslint-disable-next-line
      // @ts-ignore
      statusData.color = statusColor[nn.status];
      statusData.statusText = doingMap[nn.status] || '';
      appName.value = currentApp.name;
      disableData.initDisabled = initDisableArr.includes(nn.status);
      disableData.startDisabled = startDisableArr.includes(nn.status);
      disableData.stopDisabled = stopDisableArr.includes(nn.status);

      // 停止中开启tsf推送轮询
      if (Number(nn.status) === 30) {
        if (!syncTimer) {
          syncStatusTimer();
        }
      } else {
        clearSyncTimer();
      }
    });

    const detailVisible = ref(false);
    // 开关基础信息弹框
    const toggle = () => {
      detailVisible.value = !detailVisible.value;
    };
    // 打开git仓库
    const openGit = () => {
      if (currentApp.value.sshHost && currentApp.value.deposit) {
        window.open(currentApp.value.sshHost + currentApp.value.deposit);
      } else {
        ElMessage.error('暂无仓库信息');
      }
    };
    // 按钮loading控制
    const initLoading = ref(false);
    const startLoading = ref(false);
    const stopLoading = ref(false);

    // 打开日志弹框
    const toggleLog = () => {
      appLogRef.value.handleOpenDialog();
    };
    // 初始化应用
    const initApp = async (initTimes: number, source: number) => {
      initLoading.value = true;
      currentApp.value.status = 10;
      disableData.initDisabled = true;
      try {
        const { code } = await initApplyApp(currentApp.value.id, initTimes, source);
        if (code === 0) {
          ElMessage.success(initTimes > 0 ? '同步配置指令下发成功' : '初始化指令下发成功');
          getAppDetail(String(appId));
          toggleLog();
        }
        initLoading.value = false;
      } catch (e) {
        initLoading.value = false;
      }
      disableData.initDisabled = false;
    };

    const start = async () => {
      openDomainDialog();
    };

    const stop = async () => {
      stopLoading.value = true;
      currentApp.value.status = 30;
      disableData.stopDisabled = true;
      try {
        const { code } = await stopApp(currentApp.value.id);
        if (code === 0) {
          ElMessage.success('停止指令下发成功');
          getAppDetail(String(appId));
          toggleLog();
        }
        stopLoading.value = false;
      } catch (e) {
        stopLoading.value = false;
      }
      disableData.stopDisabled = false;
    };

    // 确定启动
    const handleConfirmStart = async (configData: any) => {
      startLoading.value = true;
      currentApp.value.status = 20;
      try {
        const { code } = await startApp({
          serviceId: currentApp.value.id,
          branch: 'master',
          ...configData,
        });
        if (code === 0) {
          ElMessage.success('启动指令下发成功');
          getAppDetail(String(appId));
          toggleLog();
        }
        startLoading.value = false;
      } catch (e) {
        startLoading.value = false;
      }
    };
    const timer = setInterval(async () => {
      getAppDetail(String(appId));
    }, 5000);

    onBeforeUnmount(() => {
      clearInterval(timer);
      clearSyncTimer();
      removeEnvStorage();
    });

    const changeApp = (e: any) => {
      let url = '';
      if (envId) {
        url = `/env/${env.toLocaleLowerCase()}/${envId}/main-app/${e}?env=${env}&envName=${envName}`;
      } else {
        url = `/apps/main/${e}`;
      }
      window.location.replace(url);
    };
    const handlePublishVersion = () => {
      releaseDialogRef.value.handleOpenDialog(currentApp.value);
    };

    const { isRefrenceApp, isDisableSync } = useRefrenceApp(currentApp);

    const isMaster = computed(
      () => currentApp?.value?.serviceVersionType === 1 && currentApp.value.serviceVersion === 'master',
    );

    return {
      statusData,
      currentApp,
      detailVisible,
      toggle,
      currentId,
      appList,
      openGit,
      initApp,
      start,
      stop,
      appLogRef,
      toggleLog,
      changeApp,
      appName,
      ...toRefs(disableData),
      getShowBool,
      releaseDialogRef,
      handlePublishVersion,
      getServiceShowName,
      activeName,
      handleConfirmStart,
      env,
      isRefrenceApp,
      isDisableSync,
      isMaster,
      getTemplateTIcon,
      templateEnum,
    };
  },
});
