
import {
  defineComponent,
  reactive,
  Ref,
  ref,
  toRefs,
  unref,
  onMounted,
  watch,
  nextTick,
  inject,
  PropType,
  computed,
} from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { getAppsMainsTree, getAppsMains, postAppsMains, postMainMenus, postAppsDelete } from '@/api/servers';
import { ElMessage, ElMessageBox } from 'element-plus';
import { useEnvAndServiceId } from '@/views/env/detail/detail';
import { REFRENCE_APP } from '../../useRefrenceApp';
import { App } from '../../type';
const validateDescription = (rule: any, value: any, callback: any) => {
  if (value.length > 512) {
    return callback(new Error('描述长度不能大于512'));
  }
  callback();
};
export default defineComponent({
  name: 'AppMenu',
  props: {
    detail: {
      type: Object as PropType<App>,
      default: () => ({}),
    },
  },
  setup(props) {
    const appInfo: Ref<App> = ref(props.detail);
    watch(
      () => props.detail,
      (value: App) => {
        appInfo.value = value;
      },
    );
    const { serviceId: appId } = useEnvAndServiceId();
    const state = reactive({
      parentId: null,
      isFirFlag: true,
      parentList: [
        {
          id: null,
          name: '',
          eName: '',
        },
      ],
      childrenList: [
        {
          id: null,
          name: '',
          eName: '',
        },
      ],
      defaultExpand: '',
      isModule: '',
      isFlag: true,
      loading: true,
      isAdd: true,
      ruleFormRef: null,
      flag: false,
      tree: null,
      dataList: [
        {
          id: null,
          name: '',
          eName: '',
        },
      ],
      propsInfo: {
        value: 'serviceVersion',
        children: 'versions',
        label: 'serviceVersion',
      },
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      dialogVisible: false,
      childrenId: null,
      getAppsList: [],
      modules: [],
      ruleForm: {
        name: '',
        appId: Number(appId),
        eName: '',
        parentId: null,
        module: '',
        path: '',
        description: '',
        icon: '',
        id: '',
        code: '',
      },
      ruleFormCopy: {},

      rules: {
        eName: [
          {
            required: true,
            message: '请输入菜单英文名',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 128,
            message: '菜单英文名在1-128字符',
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: '请输入菜单展示名',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 128,
            message: '菜单展示名长度在1-128字符',
            trigger: 'blur',
          },
        ],
        desc: [
          {
            max: 2048,
            message: '菜单英文名长度在0-2048字符',
            trigger: 'blur',
          },
        ],
        description: [{ validator: validateDescription, trigger: 'blur' }],
      },
    });

    // 获取树列表
    const getAppsTree = async () => {
      const { data } = await getAppsMainsTree({ appId });
      state.dataList = data || [];
      state.isFirFlag = true;
      const treeref: any = unref(state.tree);
      if (data?.[0]) {
        state.childrenList = data[0].children;
        state.defaultExpand = data[0].id;
        if (treeref) {
          treeref.setCurrentKey(data[0].id);
        }
        console.log(data[0]);

        state.ruleFormCopy = JSON.parse(JSON.stringify(data[0]));
        const { name, eName, module, path, description, icon, id, code } = JSON.parse(JSON.stringify(data[0]));
        state.ruleForm.name = name;
        state.ruleForm.eName = eName;
        state.ruleForm.module = module;
        state.isModule = module;
        state.ruleForm.path = path;
        state.ruleForm.description = description;
        state.ruleForm.icon = icon;
        state.ruleForm.code = code;
        state.childrenId = id;
        state.parentId = null;
      }
      console.log(state.ruleForm);

      if (data?.length > 0) {
        state.isFlag = false;
      }
    };
    const mainApiChange = (serviceName: any) => {
      if (serviceName) {
        const appName = serviceName;
        // const app: any = state.getAppsList.find(
        //   (item: any) => item.appName === appName
        // );
        state.ruleForm.module = appName;
        const app: any = state.getAppsList.find((item: any) => item.appName === appName);
        state.ruleForm.code = app?.appId ? `${app?.appId}` : '';
      } else {
        state.ruleForm.module = '';
        state.ruleForm.code = '';
      }
    };

    // 获取模块接口
    const getAppsListFun = async () => {
      try {
        const { data = [] } = await getAppsMains({ appId });
        state.getAppsList = data.map((item: any) => ({
          ...item,
          showName: `${item.name} / ${item.appName}`,
        }));
      } catch (e) {
        console.log(e);
      }
    };
    // 移动结束
    const handleDragEnd = async (Node: any, EndNode: any, dropType: any) => {
      const treeNodeLen = EndNode.data.children.filter((item: any) => item.eName === Node.data.eName);
      if (treeNodeLen.length > 1) {
        ElMessage({
          type: 'warning',
          message: '菜单英文名不能重复',
        });
        getAppsTree();
        return;
      }
      if (EndNode.data.module && dropType === 'inner') {
        ElMessage({
          type: 'warning',
          message: '有关联模块的菜单不允许添加子集！',
        });
        getAppsTree();
      } else {
        const paramData: any = [];
        const data = dropType !== 'inner' ? EndNode.parent.data : EndNode.data;
        const nodeData = EndNode.level === 1 && dropType !== 'inner' ? data : data.children;
        // 设置父ID,当level为1说明在第一级，pid为0
        nodeData.forEach(() => {
          state.parentId = data.id || null;
        });
        nodeData.forEach((element: any, i: number) => {
          const dept = {
            id: element.id,
            parentId: state.parentId,
            sort: i,
          };
          paramData.push(dept);
        });

        const { code } = await postMainMenus(Node.data.id, paramData);
        if (code === 0) {
          ElMessage({
            type: 'success',
            message: '移动成功',
          });
          state.dialogVisible = false;
          getAppsTree();
        }
      }
    };
    // 清除表单内容
    const clearFunRefrom = async () => {
      const form: any = unref(state.ruleFormRef);
      state.ruleForm.name = '';
      state.ruleForm.eName = '';
      state.ruleForm.module = '';
      state.ruleForm.path = '';
      state.ruleForm.description = '';
      state.ruleForm.icon = '';
      state.ruleForm.id = '';
      state.ruleForm.code = '';
      nextTick(() => {
        if (form) {
          form.clearValidate('module');
        }
      });
    };
    //  新增同级
    const addSame = () => {
      clearFunRefrom();
      state.modules = [];
      state.dialogVisible = true;
      state.isAdd = true;
      state.isFirFlag = true;
      state.ruleForm.parentId = state.parentId;
      // state.ruleForm.code = uuid;
    };

    //  新增子集
    const addChild = () => {
      if (state.isModule) {
        ElMessage({
          type: 'warning',
          message: '有关联模块的菜单不允许添加子集！',
        });
      } else {
        clearFunRefrom();
        state.isFirFlag = false;
        state.modules = [];
        state.dialogVisible = true;
        state.isAdd = true;
        state.ruleForm.parentId = state.childrenId;
        // state.ruleForm.code = uuid;
      }
    };

    // 节点被点击
    const nodeClick = (data: any, Node: any) => {
      state.childrenId = data.id;
      state.isModule = data.module;
      console.log(Node);
      state.parentId = Node.parent.data.id || null;
      state.parentList = Node.parent.data.children;
      state.childrenList = Node.data.children;
      state.ruleFormCopy = JSON.parse(JSON.stringify(data));
      state.isFlag = false;
    };

    // 编辑
    const editChild = async (ruleFormCopy: any) => {
      state.ruleForm = JSON.parse(JSON.stringify(ruleFormCopy));
      state.dialogVisible = true;
      state.modules = JSON.parse(JSON.stringify(ruleFormCopy)).module.split('::');
      state.isAdd = false;
    };

    // 新增、编辑确定
    const updateAppDetail = async () => {
      const form: any = unref(state.ruleFormRef);
      if (!form) return;
      try {
        await form.validate();
        state.loading = false;
        if (state.isAdd) {
          // eslint-disable-next-line @typescript-eslint/prefer-for-of
          for (let index = 0; index < state.dataList.length; index++) {
            if (state.ruleForm.parentId === null) {
              if (
                state.dataList[index].name === state.ruleForm.name ||
                state.dataList[index].eName === state.ruleForm.eName
              ) {
                ElMessage({
                  type: 'warning',
                  message: '同级目录下菜单展示名、菜单英文名不能重复',
                });
                state.loading = true;
                return false;
              }
            }
          }
          // 子节点--判断是否唯一
          if (state.childrenList && state.ruleForm.parentId && !state.isFirFlag) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let index = 0; index < state.childrenList.length; index++) {
              if (
                state.childrenList[index].name === state.ruleForm.name ||
                state.childrenList[index].eName === state.ruleForm.eName
              ) {
                ElMessage({
                  type: 'warning',
                  message: '同级目录下菜单展示名、菜单英文名不能重复',
                });
                state.loading = true;
                return false;
              }
            }
          }
          // 同级节点--非一级节点--判断是否唯一
          if (state.parentList && state.ruleForm.parentId && state.isFirFlag) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let index = 0; index < state.parentList.length; index++) {
              if (
                state.parentList[index].name === state.ruleForm.name ||
                state.parentList[index].eName === state.ruleForm.eName
              ) {
                ElMessage({
                  type: 'warning',
                  message: '同级目录下菜单展示名、菜单英文名不能重复',
                });
                state.loading = true;
                return false;
              }
            }
          }
          console.log(state.ruleForm.parentId);
          state.ruleForm.code = `${state.ruleForm.eName}`;
          const payload = {
            ...state.ruleForm,
            uniqueCode: uuidv4(),
          };
          const { code } = await postAppsMains(payload);
          if (code === 0) {
            state.loading = true;
            ElMessage({
              type: 'success',
              message: '新增成功',
            });
            state.dialogVisible = false;
            getAppsTree();
          } else {
            state.loading = true;
            ElMessage({
              type: 'error',
              message: '新增失败',
            });
          }
        } else {
          // eslint-disable-next-line @typescript-eslint/prefer-for-of
          for (let index = 0; index < state.dataList.length; index++) {
            if (state.ruleForm.parentId === null) {
              if (state.dataList[index].id !== state.ruleForm.id) {
                if (
                  state.dataList[index].name === state.ruleForm.name ||
                  state.dataList[index].eName === state.ruleForm.eName
                ) {
                  ElMessage({
                    type: 'warning',
                    message: '同级目录下菜单展示名、菜单英文名不能重复',
                  });
                  state.loading = true;
                  return false;
                }
              }
            }
          }
          // 子节点--判断是否唯一
          if (state.childrenList && state.ruleForm.parentId && !state.isFirFlag) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let index = 0; index < state.childrenList.length; index++) {
              if (state.childrenList[index].id !== state.ruleForm.id) {
                if (
                  state.childrenList[index].name === state.ruleForm.name ||
                  state.childrenList[index].eName === state.ruleForm.eName
                ) {
                  ElMessage({
                    type: 'warning',
                    message: '同级目录下菜单展示名、菜单英文名不能重复',
                  });
                  state.loading = true;
                  return false;
                }
              }
            }
          }
          // 同级节点--非一级节点--判断是否唯一
          if (state.parentList && state.ruleForm.parentId && state.isFirFlag) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let index = 0; index < state.parentList.length; index++) {
              if (state.parentList[index].id !== state.ruleForm.id) {
                if (
                  state.parentList[index].name === state.ruleForm.name ||
                  state.parentList[index].eName === state.ruleForm.eName
                ) {
                  ElMessage({
                    type: 'warning',
                    message: '同级目录下菜单展示名、菜单英文名不能重复',
                  });
                  state.loading = true;
                  return false;
                }
              }
            }
          }
          const { code } = await postMainMenus(state.ruleForm.id, [
            {
              id: state.ruleForm.id,
              parentId: state.ruleForm.parentId,
              name: state.ruleForm.name,
              eName: state.ruleForm.eName,
              description: state.ruleForm.description,
              module: state.ruleForm.module,
              path: state.ruleForm.path,
              icon: state.ruleForm.icon,
              code: `${state.ruleForm.eName}`,
            },
          ]);
          if (code === 0) {
            state.loading = true;
            ElMessage({
              type: 'success',
              message: '编辑成功',
            });
            state.dialogVisible = false;
            form.clearValidate();
            getAppsTree();
          } else {
            state.loading = true;
            ElMessage({
              type: 'error',
              message: '编辑失败',
            });
          }
        }
      } catch (error) {
        return false;
      }
    };
    // 展开/折叠
    const DynamicScaling = () => {
      const treeref: any = unref(state.tree);
      for (const [index] of treeref.store._getAllNodes().entries()) {
        treeref.store._getAllNodes()[index].expanded = state.flag;
      }
    };

    //  展开折叠
    const operation = () => {
      state.flag = !state.flag;
      DynamicScaling();
    };

    // 删除
    const delData = async () => {
      ElMessageBox.confirm(`是否删除该应用？`, '提示', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { code } = await postAppsDelete(String(state.childrenId));
          if (code === 0) {
            ElMessage({
              type: 'success',
              message: '删除成功',
            });
            getAppsTree();
          } else {
            ElMessage({
              type: 'error',
              message: '删除失败',
            });
          }
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '取消删除',
          });
        });
    };
    onMounted(() => {
      getAppsTree();
      getAppsListFun();
    });
    const isRefrenceApp = inject(REFRENCE_APP);
    const microAppList = computed(() =>
      state.getAppsList.filter((item: any) => item.templateType === appInfo.value.templateType),
    );
    console.log('microAppList', microAppList);
    return {
      ...toRefs(state),
      appId,
      handleDragEnd,
      getAppsTree,
      addSame,
      addChild,
      operation,
      delData,
      DynamicScaling,
      getAppsListFun,
      updateAppDetail,
      nodeClick,
      editChild,
      clearFunRefrom,
      mainApiChange,
      isRefrenceApp,
      microAppList,
    };
  },
});
